import React from 'react';
import { Badge, Table, Switch, ConfigProvider } from 'antd';

const expandColumns = [
    {
        title: 'Defect Name',
        dataIndex: 'name',
        key: 'defect',
    },
    {
        title: 'Defect Id',
        dataIndex: 'id',
        key: 'defect_id',
    },
    {
        title: 'Status',
        dataIndex: 'is_active',
        key: 'is_active',
        render: (Text) => <Badge status="success" text={Text ? "Active" : "Inactive"} />,
    },
    {
        title: 'Color Code',
        dataIndex: 'color_code',
        key: 'Color',
    },
    {
        title: 'Toggle',
        key: 'state',
        render: () => <ConfigProvider
            theme={{
                components: {
                    Switch: {
                        colorPrimary: "#298f29"
                    },
                },
            }}
        >
            <Switch defaultChecked />
        </ConfigProvider>,
    },
];

const columns = [
    {
        title: 'Machine Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Machine ID',
        dataIndex: 'id',
        key: 'id',
    },
];

const App = ({ defectsdata, machinesdata }) => {
    console.log(defectsdata, machinesdata)

    const expandDataSource = Array.from({
        length: defectsdata.length,
    }).map((_, i) => ({
        key: i.toString(),
        name: defectsdata[i].name,
        id: defectsdata[i].id,
        is_active: defectsdata[i].is_active,
        color_code: defectsdata[i].color_code,
    }));

    const dataSource = Array.from({
        length: machinesdata.length,
    }).map((_, i) => ({
        key: i.toString(),
        name: machinesdata[i].name,
        id: machinesdata[i].id,

    }));
    const expandedRowRender = () => (
        <Table columns={expandColumns} dataSource={expandDataSource} pagination={false} />
    );

    return (
        <>
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            colorBgContainer: '#fff', // Custom table background color/
                            colorPrimary: '#000',     // Custom primary color
                            colorFillAlter: '#fff',   // Hover background color for rows
                            controlHeight: 48,        // Row height
                            headerBg: '#ad3737',      // Custom header background
                            headerColor: '#fff',      // Custom header text color
                            rowHoverBg: "#e6f7ff",
                            padding: "1rem",
                            boxShadowSecondary: "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
                            fontWeightStrong: 500,
                        },
                    },
                }}
            >
                <Table
                    columns={columns}
                    className="custom-table"
                    style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}
                    pagination={false}
                    expandable={{
                        expandedRowRender,

                    }}
                    dataSource={dataSource}
                />
            </ConfigProvider>
        </>
    );
};

export default App;
